import NextImage from "components/NextImage";
import SimpleCenteredModal from "modals/SimpleCenteredModal";
import styles from "./styles.module.css";
import { GothamMedium, GothamRegular } from "components/Text";
import { jackColors } from "assets/colors";
import { ButtonJack } from "components/ButtonsJack/parent";

const BlockedUserModal = ({ isOpen = false, toggle = () => {} }) => {
  const handleClickContactUs = () => {
    window.open("https://wa.me/6285282051660", "_blank");
  };

  return (
    <SimpleCenteredModal
      woLineDividers
      isOpen={isOpen}
      toggle={toggle}
      customButtons={
        <ButtonJack
          type="outline"
          style={{ width: "100%" }}
          onClick={handleClickContactUs}
        >
          Contact Us
        </ButtonJack>
      }
    >
      <div className={styles.container}>
        <NextImage
          src="/images/three-person-in-rounded-frame-with-exclamation-mark.png"
          alt="Three person in rounded frame with exclamation mark."
          width={160}
          height={160}
        />
        <GothamMedium
          className="font20"
          style={{
            color: jackColors.neutral900,
            textAlign: "center",
            marginTop: "32px",
          }}
        >
          Some of your account details need to be updated
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{
            color: jackColors.neutral800,
            textAlign: "center",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          We sincerely apologized for the inconvenience. Please contact us to
          continue with the required verification process.
        </GothamRegular>
      </div>
    </SimpleCenteredModal>
  );
};

export default BlockedUserModal;
