import Turnstile from "react-turnstile";
import { isProduction } from "../../../components/tools";
import { useEffect, useState } from "react";
import { JackIcons } from "assets/jackIcons/parent";

const JackTurnstile = ({ onVerify }) => {
  useEffect(() => {
    const { href } = window.location;
    const cleanUrl = href.includes("?") ? href.split("?")[0] : href;

    const isAutoVerify =
      !isProduction ||
      String(cleanUrl).includes("deploy-preview") ||
      String(cleanUrl).includes("internal"); // if its a deploy preview, ignore turnstile

    onVerify(isAutoVerify);
  }, []);

  if (!isProduction) return null;

  return (
    <div>
      <Turnstile
        size="normal"
        sitekey="0x4AAAAAAANMlwaJnK_kBHqU"
        onVerify={() => onVerify(true)}
        theme="light"
      />
    </div>
  );
};

export const TurnstilePage = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);

  if (!isVerified)
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div>
          <JackIcons name="jack-horizontal" style={{ marginBottom: 20 }} />
          <JackTurnstile onVerify={(val) => setIsVerified(val)} />
        </div>
      </div>
    );

  return children;
};
