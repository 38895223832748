import React, { useEffect } from "react";
import { useHeader } from "../contexts/Layout/parent";
import { LoginPage } from "../pageComponents/loginPageComponents/parent";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { getNameSpaces } from "@namespaces";

const Login = () => {
  const { setHeader } = useHeader();
  useEffect(() => setHeader({}), []);

  return <LoginPage />;
};

export default Login;

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, getNameSpaces)),
      // Will be passed to the page component as props
    },
  };
}
