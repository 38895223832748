import { useTranslation } from "react-i18next";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamRegular } from "../../../components/Text";
import { windowDimension } from "../../../components/tools";

export const Dot = ({
  dotColor = jackColors.black34,
  marginLeft = 12,
  marginRight = 12,
  width = 4,
  height = 4,
}) => (
  <div
    style={{
      width,
      height,
      borderRadius: (width + height) / 2,
      backgroundColor: dotColor,
      marginLeft,
      marginRight,
    }}
  />
);

export const LoginFooter = () => {
  const onClick = (value) => window.open(`/tnc-login?value=${value}`, "_blank");
  const { height, isTabOrPhone: isResponsive } = windowDimension();
  const isFixed = height >= 650;
  const { t } = useTranslation("login/login");

  return (
    <GothamRegular
      style={{
        ...(isFixed
          ? {
              position: "fixed",
              bottom: 32,
            }
          : {
              marginTop: 190,
            }),

        color: jackColors.black34,
        alignItems: "center",
      }}
      className="d-flex font12"
    >
      {!isResponsive && <CopyrightJack />}
      <span className="hover" onClick={() => onClick(1)}>
        {t("Jack Service Agreement")}
      </span>
      <Dot />
      <span className="hover" onClick={() => onClick(2)}>
        {t("Privacy Policy")}
      </span>
    </GothamRegular>
  );
};

const CopyrightJack = () => {
  const { t } = useTranslation("login/login");
  return (
    <>
      {t("Copyright")}
      <JackIcons
        name="copyright"
        fill={jackColors.black34}
        style={{ width: 12, height: 12, marginLeft: 3, marginRight: 3 }}
      />
      {t("2023 Jack")}
      <Dot />
    </>
  );
};
