import { useForm } from "@tools";
import { phoneCode } from "assets/phoneCode";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { colors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { CreatableJackCustom } from "../../../components/inputs/customCreatable";
import { SelectionJack } from "../../../components/inputs/selection";
import { TextFieldJack } from "../../../components/inputs/textfield";
import { fetch, useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { requiredAndOptional } from "../../kybJackComponents/requiredAndOptional";
import { Layout } from "../components";
import styles from "../layout.module.css";
import { ServiceAgreementCheckbox } from "./agreement";
import { ProductNeeds } from "./selection";
import { HIT_REGISTER_EVENT } from "pageComponents/kybJackComponents/parent";
import { useTranslation } from "react-i18next";

const SecondStep = ({
  setCurrentScreen,
  setCurrentStep,
  setEmailSent,
  payload: payloadProps,
}) => {
  const { additional_info: additional_infoRaw, ...prevPayload } = payloadProps;
  const { query } = useRouter();
  const {
    event,
    referral_code,
    utm_source = "",
    utm_medium = "",
    utm_campaign = "",
    mobile_country_iso,
  } = query;
  const { t } = useTranslation("kyb/kyb");

  const useFormObj = useForm();
  const { watch, handleSubmit, setError, setValue, getValues } = useFormObj;

  const formWatch = watch([
    "name",
    "industry",
    "total_employees",
    "products",
    "sa",
    "average_monthly_turnover",
  ]);

  const isAllFilled = Object.values(formWatch).every((item) => item);
  const isButtonDisabled = !isAllFilled;

  const { mutation: mutationRegister, loading: loadingRegister } = useMutation({
    type: "long",
    url: "/business_partners",
    method: "post",
    afterSuccess: (_, payload) => {
      setCurrentScreen("checkEmailRegister");
      setEmailSent(prevPayload.user.email);

      const { password, password_confirmation, ...userWoPass } = payload.user;

      payload.user = userWoPass;

      eventsTracker(HIT_REGISTER_EVENT, payload);
    },
  });

  const { data: industryOptions, loading: loadingIndustry } = fetch({
    url: "/industry_list?per_page=50",
    formatter: (res) => {
      const result = (res?.data || [])
        .map((item) => {
          const { category } = item;
          return { ...item, label: category, value: category };
        })
        .sort((a, b) =>
          a.category > b.category ? 1 : a.category < b.category ? -1 : 0
        );
      return result;
    },
  });

  const loading = loadingRegister || loadingIndustry;

  const prevHandler = () => setCurrentStep(1);

  const submit = (data) => {
    const { website } = data;

    if (website) {
      if (website) {
        const regex =
          /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

        if (!regex.test(website))
          return setError("website", { message: "invalid website format" });
      }
    }

    const {
      total_employees,
      products,
      sa,
      introduced_to_jack,
      industry,
      average_monthly_turnover,
      ...rest
    } = data;

    const { isNew, value } = industry || {};

    const additional_data = {
      utm_source,
      utm_medium,
      utm_campaign,
    };

    const additional_info = {
      ...additional_infoRaw,
      average_monthly_turnover: average_monthly_turnover.value,
    };

    const product_needs = { products };

    if (!industry || isEmpty(industry)) return null;

    const businessKey = isNew
      ? {
          ...rest,
          total_employees: total_employees?.value,
          product_needs,
          industry_type: value,
        }
      : {
          ...rest,
          total_employees: total_employees?.value,
          product_needs,
          industry_type_id: value,
        };

    const payload = {
      ...prevPayload,
      business: businessKey,
    };

    if (event) {
      payload.business = {
        ...payload.business,
        initiator: `event_${event}`,
      };
    }

    payload.business = {
      ...payload.business,
      additional_info,
      additional_data,
    };

    if (referral_code !== undefined) {
      payload.business.referral_code = referral_code;
    }

    payload.user.country_code =
      phoneCode.find(({ code }) => code == mobile_country_iso)?.value || "+62";
    mutationRegister(payload);
  };

  //to prevent trigger prevHandler when user press enter in input

  const buttonContainerStyle = {
    display: "flex",
    gap: "16px",
    justifyContent: "flex-end",
  };

  const leftIcon = <JackIcons name="arrow-back" fill={colors.neutral900} />;

  const array = [
    { label: "Corporate Cards", value: "cards" },
    { label: "International Transfer", value: "international_transfer" },
    { label: "Invoice Payment", value: "invoice_payment" },
    { label: "Local Transfer", value: "local_transfer" },
    { label: "Payroll", value: "payroll" },
    { label: "Reimbursement", value: "reimbursement" },
  ];

  const sizes = [
    { label: "1-49 employees", value: "Less than or equal 49" },
    { label: "50 - 249 employees", value: "Between 50 to 249" },
    { label: "More than 250 employees", value: '"More than or equal 250"' },
  ];

  const monthlyTurnover = [
    { label: "< IDR 1 million", value: "< IDR 1 million" },
    { label: "IDR 1 - 10 million", value: "IDR 1 - 10 million" },
    { label: "> IDR 10 - 25 million", value: "> IDR 10 - 25 million" },
    { label: "> IDR 25 - 50 million", value: "> IDR 25 - 50 million" },
    { label: "> IDR 50 - 100 million", value: "> IDR 50 - 100 million" },
    { label: "> IDR 100 - 500 million", value: "> IDR 100 - 500 million" },
    {
      label: "> IDR 500 million - 1 billion",
      value: "> IDR 500 million - 1 billion",
    },
    { label: "> IDR 1 - 5 billion", value: "> IDR 1 - 5 billion" },
    { label: "> IDR 5 billion", value: "> IDR 5 billion" },
  ];

  const { optional, required } = requiredAndOptional();

  return (
    <Layout
      title="Tell us about your business"
      setCurrentScreen={setCurrentScreen}
    >
      <form className={styles["form"]} onSubmit={handleSubmit(submit)}>
        <TextFieldJack
          label={required("Business Legal Name")}
          placeholder="Enter your business name"
          name="name"
          useFormObj={useFormObj}
        />

        <CreatableJackCustom
          label={required("Industry")}
          useFormObj={useFormObj}
          name="industry"
          options={industryOptions}
          placeholder={t("Select Industry")}
        />

        <SelectionJack
          label={required("Number of Employees")}
          name="total_employees"
          placeholder="Select number of employees"
          options={sizes}
          useFormObj={useFormObj}
          woAsterisk
        />

        <SelectionJack
          label={required("Average Monthly Turnover (in IDR or equivalent)")}
          name="average_monthly_turnover"
          placeholder="Select average montly turnover"
          options={monthlyTurnover}
          useFormObj={useFormObj}
          woAsterisk
        />

        <ProductNeeds
          useFormObj={useFormObj}
          name="products"
          label="Product Needs"
          options={array}
        />

        <TextFieldJack
          label={optional("Website/Social Media Link")}
          name="website"
          useFormObj={useFormObj}
          placeholder="Enter your business website or social media link"
        />

        {/* <SimpleTextAreaJack
          useFormObj={useFormObj}
          name="introduced_to_jack"
          maxLength={120}
          placeholder="Enter the company name that referred you to us"
          label={
            <>
              Who introduced you to Jack?{" "}
              <span className="font10" style={{ color: jackColors.grey90 }}>
                (Optional)
              </span>
            </>
          }
        /> */}

        <ServiceAgreementCheckbox name="sa" useFormObj={useFormObj} />

        <div style={buttonContainerStyle}>
          <ButtonJack
            leftIcon={leftIcon}
            type="outline"
            onClick={prevHandler}
            buttonType="button"
          >
            Back
          </ButtonJack>
          <ButtonJack
            isLoading={loading}
            disabled={isButtonDisabled}
            buttonType="submit"
          >
            Register
          </ButtonJack>
        </div>
      </form>
    </Layout>
  );
};

export default SecondStep;
