import React, { useState } from "react";
import { Layout } from "../components";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { jackColors } from "../../../assets/colors";
import { CheckBox } from "../../../components/Checkbox";
import { NextAndBack } from "../../kybJackComponents/buttons";
import { pickBy } from "lodash";

const Container = ({ children, title }) => (
  <Layout title={title} setCurrentScreen={() => {}} woJumper title100 woGap>
    <div style={{ height: 32 }} />
    {children}
  </Layout>
);

const toLabelValue = (array) => array.map((value) => ({ value, label: value }));

export const EventRegisterForm = ({
  onSubmit: onSubmitProps,
  defaultValue = {},
}) => {
  const [payload, setPayload] = useState(defaultValue);

  const onSubmit = (val) => setPayload((prev) => ({ ...prev, ...val }));
  const onBack = (arr) =>
    setPayload(pickBy(payload, (_, key) => !arr.includes(key)));

  const { is_using_desty, desty_product, monthly_revenue } = payload;

  const isMonthlyRevenue = !!(
    is_using_desty === false || (desty_product || []).length
  );

  if (isMonthlyRevenue)
    return (
      <Selection
        array={toLabelValue([
          "IDR0 - IDR200.000.000/month",
          "IDR201.000.000 - IDR500.000.000/month",
          "IDR500.000.000 - IDR1.000.000.000/month",
          "IDR1.000.000.000 - IDR2.000.000.000/month",
          ">IDR2.000.000.000/month",
        ])}
        onSubmit={(monthly_revenue) => onSubmit({ monthly_revenue })}
        title="Select the approximate revenue of your business"
        onBack={() =>
          onBack([
            "monthly_revenue",
            is_using_desty ? "desty_product" : "is_using_desty",
          ])
        }
        onSubmitNext={() => onSubmitProps(payload)}
        disabled={!monthly_revenue}
      />
    );

  if (is_using_desty) {
    return (
      <SelectionMulti
        onBack={() => {
          onBack(["desty_product", "is_using_desty"]);
        }}
        onSubmit={(desty_product) => onSubmit({ desty_product })}
        array={toLabelValue([
          "Desty Omni",
          "Desty Page",
          "Desty Store",
          "Desty Menu",
        ])}
        title="Which Desty product are you currently using?"
      />
    );
  }

  return (
    <Selection
      array={[
        { label: "Yes", value: true },
        { label: "No", value: false },
      ]}
      onSubmit={(is_using_desty) => onSubmit({ is_using_desty })}
      title="Are you using Desty in your business?"
    />
  );
};

const Selection = ({
  array,
  title,
  onSubmit,
  onBack,
  onSubmitNext,
  disabled,
}) => {
  const [activeValue, setActiveValue] = useState(false);
  return (
    <Container title={title}>
      {array.map((item, index) => {
        const { label, value } = item;
        const isActive = activeValue == value;

        const style = { width: "100%", marginBottom: 16 };

        const activeStyle = isActive
          ? { boxShadow: "inset 0px 0px 0px 1px #343434" }
          : {};
        return (
          <ButtonJack
            key={index}
            type="outline"
            style={{ ...style, ...activeStyle }}
            onClick={() => {
              setActiveValue(value);
              onSubmit(value);
            }}
          >
            {label}
          </ButtonJack>
        );
      })}
      {!!onBack && (
        <div className="w-100">
          <NextAndBack
            onBack={onBack}
            onClick={onSubmitNext}
            disabled={disabled}
          />
        </div>
      )}
    </Container>
  );
};
const SelectionMulti = ({ onSubmit, onBack, array = [], title = "" }) => {
  const [selectedArr, setSelectedArr] = useState([]);

  return (
    <Container title={title}>
      <GothamRegular style={{ color: jackColors.grey90, marginBottom: 32 }}>
        You can select more than one
      </GothamRegular>
      <div
        className="d-flex w-100"
        style={{ flexDirection: "column", gap: 16 }}
      >
        {array.map((item, index) => {
          const { label, value } = item;
          return (
            <div
              key={index}
              className="w-100 d-flex justify-content-between hover"
              onClick={() =>
                setSelectedArr((prev) => {
                  const isIncludes = prev.includes(value);
                  if (isIncludes) return prev.filter((i) => i !== value);

                  return [...prev, value];
                })
              }
              style={{
                border: `1px solid ${jackColors.greyE6}`,
                borderRadius: 4,
                padding: 10,
              }}
            >
              <GothamMedium>{label}</GothamMedium>
              <CheckBox isActive={selectedArr.includes(value)} />
            </div>
          );
        })}
        <NextAndBack
          onClick={() => onSubmit(selectedArr)}
          onBack={onBack}
          disabled={!selectedArr.length}
        />
      </div>
    </Container>
  );
};
