import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "@tools";
import { Banner } from "components/Banner";
import BlockedUserModal from "components/BlockedUserModal";
import SwitchLocaleButton from "components/LocaleButton";
import { useWindowDimension } from "contexts/DimensionContext";
import { ToasterHook } from "contexts/ToasterContext";
import { useRouter } from "next/router";
import { pluralize } from "pageComponents/invoiceComponents/data/formatter";
import { KYBRejectedPage } from "pageComponents/kybJackComponents/modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { errorApiDecider, useMutation } from "tools/api";
import { eventsTracker } from "universalFunctions/events";
import * as yup from "yup";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../components/Text";
import {
  TextFieldJack,
  TextFieldJackPassword,
} from "../../../components/inputs/textfield";
import { PopUp } from "../../dashboardComponents/popup/parent";
import { Layout } from "../components";
import styles from "../layout.module.css";
import { useLoginHook } from "../logics";
import RegisterOTP from "../register/registerOTP";
import { LoginFooter } from "./footer";
import {
  NewUserOTPModal,
  OTPModal,
  OTPOPtionModal,
  SuspendedUserModal,
  setRemainingOTPAttempts,
} from "./otp";
import { FIRST_LOGIN } from "pageComponents/kybJackComponents/parent";

const clearCookies = () => {
  const cookies = document.cookie.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name, _] = cookie.split("="); // Using destructuring to get the cookie name
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

const clearCache = async () => {
  try {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        try {
          await caches.delete(cacheName);
          console.log("Cache cleared successfully");
        } catch (error) {
          console.error("Error clearing cache:", error);
        }
      }
    } else {
      console.warn("Cache API is not supported in this browser");
    }
  } catch (error) {
    console.error("Error accessing cache:", error);
  }
};

const clearCookiesAndCache = () => {
  clearCookies();
  clearCache();
};

const LoginPageComponents = ({ setCurrentScreen }) => {
  const [isLoggedin, setIsLoggedIn] = useState(false);
  const [OTPTriesLeft, setOTPTriesLeft] = useState(0);
  const {
    isOpen: isOpenOtp,
    toggle: toggleOtp,
    open: openOtp,
    close: closeOTP,
  } = useModalHook();
  const {
    isOpen: isOpenNewUserOTP,
    close: closeNewUserOTP,
    open: openNewUserOTP,
  } = useModalHook();
  const {
    isOpen: isOpenEmailOTP,
    close: closeEmailOTP,
    open: openEmailOtp,
  } = useModalHook();
  const { errorToasterApi, errorToaster } = ToasterHook();
  const { t } = useTranslation("login/login");

  const {
    isOpen: isOpenChooseOTP,
    open: openChooseOTP,
    close: closeChooseOTP,
  } = useModalHook();

  const { isOpen: isOpenSuspendedModal, toggle: toggleSuspendedModal } =
    useModalHook();

  const { push, query, locale } = useRouter();
  const {
    toResetPin,
    email: email_query = "",
    business_id: demo_business_id = null,
    business_name: demo_business_name = "",
    maxInvalidPINReached,
    kyb_rejected,
  } = query;

  const useFormObj = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email(t("Please input a valid email address"))
          .required(t("Email is a required field")),
        password: yup.string().required(t("Password is a required field")),
      })
    ),
  });
  const { handleSubmit, setValue, watch } = useFormObj;
  const prod_email = watch("email");
  const eventPayload = {
    demo_business_id,
    demo_business_name,
    demo_email: email_query,
    prod_email,
  };
  const {
    loading,
    login: submit,
    setIsInvalidData,
  } = useLoginHook(
    setIsLoggedIn,
    () => {
      closeOTP();
      closeNewUserOTP();
    },
    eventPayload
  );

  const isToResetPin = toResetPin == "true";
  const showSuspendPINModal = maxInvalidPINReached == "true";
  useEffect(() => {
    if (!!email_query) setValue("email", email_query);
  }, [email_query]);

  useEffect(() => {
    if (!query?.business_id) return null;
    eventsTracker("page_view", {
      page_title: "login",
      demo_business_id,
      demo_business_name,
      demo_email: email_query,
    });
  }, [query?.business_id]);

  const formStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32px",
  };

  const inputContainerStyle = {
    width: "100%",
    marginBottom: "0px",
  };

  const forgotPasswordHandler = () => {
    setCurrentScreen("forgotPassword");
  };

  const registerHandler = () => {
    push("/register");
  };

  const { isOpen, toggle, close } = useModalHook(true);
  const {
    isOpen: isOpenBlockedUserModal,
    open: openBlockedUserModal,
    close: closeBlockedUserModal,
  } = useModalHook();

  const { mutation, loading: loadingRequest } = useMutation({
    url: "/authenticate/request_otp",
    method: "post",
    afterSuccess: openOtp,
  });

  const {
    mutation: chooseOTP,
    loading: loadingRequestOTP,
    result: loginRecords,
    setResult: setLoginRecords,
  } = useMutation({
    url: "/authenticate/before_request_otp",
    method: "post",
    withError: false,
    afterSuccess: async (_, payload, res) => {
      const { message, is_user_just_registered, kyb_status, email_verified } =
        res?.data;

      const isKYBEmailnotverified = !email_verified;
      const isAuthorized = message == "Authorized Device";
      const isKybRejected = kyb_status == "rejected";

      if (isKYBEmailnotverified) return openEmailOtp();
      if (isKybRejected) return push("/login?kyb_rejected=true");
      if (is_user_just_registered) {
        eventsTracker(FIRST_LOGIN);
        return openNewUserOTP();
      }
      if (isAuthorized) return submit(payload);

      openChooseOTP();
    },
    resultFormatter: (res, _, payload) => {
      const mobile = res?.data?.number;
      return { ...payload, mobile };
    },
    handleError: (errorObj, setResult) => {
      const { status, data, message: messageRaw } = errorObj?.response || {};
      const {
        error,
        user_status,
        failed_password_attempt,
        failed_pin_attempt,
        failed_otp_attempt,
        ...rest
      } = data || {};
      const message = messageRaw || data?.message;

      const is422 = status == 422;

      const isFingerprintSus = is422 && message.includes("Suspicious");

      if (isFingerprintSus)
        return errorToaster(
          _,
          t(
            "Something is wrong. Please contact Jack Account Manager. error code: 402"
          )
        );

      const emailErrorMessages = [
        "Invalid email or password",
        "Email is not registered in the database",
        "Email is not registered in the database, please contact our supports for email registration",
      ];

      const blockedErrorMessages = [
        "Account Suspended",
        "[40101] Blocked User Entry",
      ];

      const errorMessageFinder = (errorMessages) => {
        return Boolean(
          errorMessages.find((errorMessage) => {
            return (error?.message || "").includes(errorMessage);
          })
        );
      };

      const isSuspended = data?.user_status == "suspended";

      const { invalidData, isUnauthorized } = errorApiDecider(errorObj);

      if (isSuspended) {
        const suspendCausedByPIN = failed_pin_attempt >= 3;
        const suspendCausedByPassword = failed_password_attempt >= 3;
        const suspendCausedByOTP = failed_otp_attempt >= 3;

        setResult({
          condition: suspendCausedByPassword
            ? "password"
            : suspendCausedByPIN
            ? "pin"
            : suspendCausedByOTP
            ? "otp"
            : "",
          ...rest,
        });
        toggleSuspendedModal();
        return;
      }

      const isErrorBlocked = errorMessageFinder(blockedErrorMessages);
      if (isErrorBlocked) return openBlockedUserModal();

      const isErrorEmail = errorMessageFinder(emailErrorMessages);
      const isErrorPassword = (error?.message || "") == "Invalid password";
      const isErrorEmailOrPassword = isErrorEmail || isErrorPassword;
      if (!isErrorEmailOrPassword) return errorToasterApi(errorObj);

      const isWrongAccount = isUnauthorized || invalidData;

      setResult({ isWrongAccount, ...rest });
    },
  });

  const {
    mobile,
    isWrongAccount,
    otp_with,
    // failed_password_attempt: failedAttempt,
    // is_admin_or_bo: isAdmin,
    // user_status,
    remaining_attempt,
    condition,
    ...loginProps
  } = loginRecords || {};

  const { isTabOrPhone: isResponsive } = useWindowDimension();
  const hideButton = isLoggedin;

  if (kyb_rejected) return <KYBRejectedPage />;
  return (
    <>
      {!isResponsive && !hideButton && (
        <div
          className="w-100 align-items-end justify-content-end d-flex"
          // style={{ padding: 20, paddingRight: 32, gap: 16 }}
          style={{ position: "absolute", top: 20, right: 32 }}
        >
          <SwitchLocaleButton noAuth />
        </div>
      )}
      <Layout
        title={t("Welcome back to Jack!")}
        setCurrentScreen={setCurrentScreen}
        isSignedIn={isLoggedin}
      >
        <GothamRegular className={styles["grey-text"]}>
          {t("First time here?")} &nbsp;
          <TextInlineMedium
            className={styles["underline-medium"]}
            onClick={registerHandler}
          >
            {t("Register")}
          </TextInlineMedium>
        </GothamRegular>
        <form
          style={formStyle}
          onSubmit={handleSubmit(async (val) => {
            clearCookiesAndCache();
            close();
            setIsInvalidData(false);
            chooseOTP(val);
          })}
        >
          <TextFieldJack
            name="email"
            autoFocus
            label="Email"
            useFormObj={useFormObj}
            placeholder={t("Enter email address")}
            style={inputContainerStyle}
            error={isWrongAccount && t("Please input a correct email address")}
            isAutoFill
          />
          <TextFieldJackPassword
            name="password"
            useFormObj={useFormObj}
            placeholder={t("Enter your password")}
            style={inputContainerStyle}
            error={isWrongAccount && t("Please input a correct password")}
            isAutoFill
          />
          {/* <LoginPageBanner
            remainingAttempt={remaining_attempt}
            failedAttempt={failedAttempt}
          /> */}
          {remaining_attempt ? (
            <Banner
              title={`${t(
                "Incorrect email/password:"
              )} ${remaining_attempt} ${t(
                pluralize(remaining_attempt, "attempt")
              )} ${t("left")}`}
              titleStyle={{ marginTop: 2 }}
              type="warning"
            />
          ) : null}
          <GothamMedium
            className={styles["underline-medium"]}
            onClick={forgotPasswordHandler}
          >
            {t("Forgot your password?")}
          </GothamMedium>

          <ButtonJack
            isLoading={loading || loadingRequest || loadingRequestOTP}
            className={styles["button"]}
          >
            {t("Log In")}
          </ButtonJack>
        </form>
        <LoginFooter />
      </Layout>
      <OTPModal
        isOpen={isOpenOtp}
        remainingInvalidAttempt={OTPTriesLeft}
        onAction={async (type, otp_code) => {
          const isToggle = type == "toggle";
          const isResend = type == "resend";
          const isBack = type == "back";

          if (isBack) {
            openChooseOTP();
            toggleOtp();
            return;
          }

          if (isToggle) return toggleOtp();

          if (isResend) return mutation({ ...loginProps, otp_with });

          const val = { otp_code, ...loginProps };

          if (isToResetPin)
            return submit(val, false, () => {
              push({ pathname: "/reset-pin", query });
            });

          submit(val, (error) => setRemainingOTPAttempts(error, toggleOtp));
        }}
        type={otp_with}
        mobile={mobile}
        // isError={isInvalidData}
        loading={loading}
      />
      <NewUserOTPModal
        isOpen={isOpenNewUserOTP}
        toggle={closeNewUserOTP}
        loadingReqOTP={loading}
        additionalPayload={loginProps}
        onSubmit={(otp_code, toggle) => {
          const val = { otp_code, ...loginProps };
          submit(val, (error) => setRemainingOTPAttempts(error, toggle));
        }}
        defaultPhoneNumber={mobile}
      />
      <PopUp isOpen={isOpen} toggle={toggle} isLogin />
      <OTPOPtionModal
        phoneNumber={mobile}
        isOpen={isOpenChooseOTP}
        toggle={closeChooseOTP}
        onClick={async (type) => {
          setLoginRecords((p) => ({ ...p, otp_with: type }));

          mutation({ ...loginProps, otp_with: type });
          closeChooseOTP();
        }}
        // customerSupportLink={contactUsLink?.password}
      />
      <BlockedUserModal
        isOpen={isOpenBlockedUserModal}
        toggle={closeBlockedUserModal}
      />
      <SuspendedUserModal
        condition={condition}
        isOpen={isOpenSuspendedModal}
        toggle={toggleSuspendedModal}
      />
      <SuspendedUserModal
        condition={"pin"}
        isOpen={showSuspendPINModal}
        toggle={() => push("/login")}
      />
      <RegisterOTP
        isOpen={isOpenEmailOTP}
        toggle={closeEmailOTP}
        email={loginRecords?.email}
        toLogin={closeEmailOTP}
      />
    </>
  );
};

export default LoginPageComponents;
