import { useTranslation } from "react-i18next";
import { jackColors } from "../../../assets/colors";
import { TextInlineMedium } from "../../../components/Text";

export const LoginInline = ({ onClick }) => {
  const { t } = useTranslation("login/login");
  return (
    <TextInlineMedium
      className="hover"
      onClick={onClick}
      style={{
        textDecoration: "underline",
        marginLeft: 4,
        color: jackColors.black34,
      }}
    >
      {t("Log in")}
    </TextInlineMedium>
  );
};
