import { windowDimension } from "@tools";
import SwitchLocaleButton from "components/LocaleButton";
import { ToasterHook } from "contexts/ToasterContext";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import CheckEmailPageComponents from "./checkEmail/parent";
import ForgotPasswordPageComponents from "./forgotPassword/parent";
import LoginPageComponents from "./login/parent";
import { TurnstilePage } from "./login/turnstile";
import RegisterPageComponents from "./register/parent";

const CurrentScreenDecider = () => {
  const [currentScreen, setCurrentScreen] = useState("login");
  const [emailSent, setEmailSent] = useState("");

  const { pathname, query, push } = useRouter();

  useEffect(() => {
    const isRegister = pathname.includes("register");
    const isNewRegister = pathname.includes("new-register");
    if (isRegister) setCurrentScreen("register");
    if (isNewRegister) setCurrentScreen("new-register");
  }, [pathname]);

  const { mutation: mutationForgotPassword, loading: loadingForgotPassword } =
    useMutation({
      url: "/business_users/forgot_password",
      method: "post",
      afterSuccess: (_, payload) => {
        eventsTracker("forgot_password", payload);
        setCurrentScreen("checkEmailForgotPassword");
      },
      withError: false,
      handleError: (errorObj) => {
        const {
          error,
          user_status,
          failed_password_attempt,
          failed_pin_attempt,
          failed_otp_attempt,
          ...rest
        } = errorObj?.response?.data || {};
        const isSuspended = user_status == "suspended";

        if (isSuspended) {
          const suspendCausedByPIN = failed_pin_attempt >= 3;
          const suspendCausedByPassword = failed_password_attempt >= 3;
          const suspendCausedByOTP = failed_otp_attempt >= 3;

          const condition = suspendCausedByPassword
            ? "password"
            : suspendCausedByPIN
            ? "pin"
            : suspendCausedByOTP
            ? "otp"
            : "";

          return push({ pathname, query: { ...query, suspend: condition } });
        }
        errorToaster("", t("This email hasn’t been registered to Jack yet."));
      },
    });

  const { mutation: mutationResendRegister } = useMutation({
    url: "/business_users/resend_confirmation",
    method: "post",
    afterSuccess: (_, payload) => eventsTracker("resend_register", payload),
  });

  const { errorToaster } = ToasterHook();
  const { t } = useTranslation("login/login");

  const { isTabOrPhone: isResponsive } = windowDimension();

  switch (currentScreen) {
    case "register":
      return (
        <RegisterPageComponents
          setCurrentScreen={setCurrentScreen}
          setEmailSent={setEmailSent}
        />
      );
    // case "new-register":
    //   return (
    //     <RegisterPageComponents
    //       setCurrentScreen={setCurrentScreen}
    //       setEmailSent={setEmailSent}
    //       banner={query.banner || "general"}
    //     />
    //   );
    case "checkEmailForgotPassword":
    case "checkEmailRegister":
      return (
        <CheckEmailPageComponents
          type={currentScreen}
          emailSent={emailSent}
          setCurrentScreen={setCurrentScreen}
          mutationForgotPassword={mutationForgotPassword}
          mutationResendRegister={mutationResendRegister}
        />
      );
    case "forgotPassword":
      return (
        <Fragment>
          {!isResponsive && (
            <div
              className="w-100 align-items-end justify-content-end d-flex"
              style={{ position: "absolute", top: 20, right: 32 }}
            >
              <SwitchLocaleButton noAuth />
            </div>
          )}
          <ForgotPasswordPageComponents
            setCurrentScreen={setCurrentScreen}
            setEmailSent={setEmailSent}
            loading={loadingForgotPassword}
            mutation={mutationForgotPassword}
          />
        </Fragment>
      );
    default:
      return (
        <TurnstilePage>
          <LoginPageComponents setCurrentScreen={setCurrentScreen} />
        </TurnstilePage>
      );
  }
};

export const LoginPage = () => {
  const { query } = useRouter();
  const { token } = query;

  const { mutation } = useMutation({
    url: "/business_users/confirm_email",
    method: "post",
    withError: false,
  });

  useEffect(() => {
    if (!token) return;
    mutation({ token });
  }, [token]);

  return (
    <>
      <CurrentScreenDecider />
    </>
  );
};
